<template>
  <div class="tableBox">
     <div class='kc-contain'>
        <h5>
          <img src="@assets/img/profile/icon-back.png" @click="$router.go(-1)"/>购买记录
        </h5>
        <div class="list">
            <table>
              <colgroup>
                <col style="width:15%">
                <col style="width:15%">
                <col style="width:10%">
                <col style="width:10%">
                <col style="width:10%">
                <col style="width:10%">
                <col style="width:20%">
              </colgroup>
              <thead>
                <tr>
                  <th>订单号</th>
                  <th>商品名称</th>
                  <th>购买数量</th>
                  <th>商品价格</th>
                  <th>折扣</th>
                  <th>消费金额</th>
                  <th>消费时间</th>
                </tr>
              </thead>
            </table>
          <div class="pan-table__body" @scroll="eventScrooll($event)">
            <table>
              <colgroup>
                <col style="width:15%">
                <col style="width:15%">
                <col style="width:10%">
                <col style="width:10%">
                <col style="width:10%">
                <col style="width:10%">
                <col style="width:20%">
              </colgroup>
              <tbody>
                <tr v-for="item in orderList" :key="item.id">
              <td>{{item.order_number}}</td>
              <td>{{item.title}}</td>
              <td>{{ item.num }}</td>
              <td>￥{{item.old_price?item.old_price:0}}</td>
              <td>{{item.discount?item.discount * 10:'-'}}</td>
              <td>￥{{item.pay_price?item.pay_price:0}}</td>
              <td>{{item.create_time}}</td>
            </tr>
             </tbody>
            </table>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import {getOrderList} from "@assets/api/myWallet/myWallet";

export default {
  name:'',
  components: {},

  data () {
    return {
      pageIndex:1,//分页
      orderList:[],
      state:false,
    };
  },

  computed: {},

  created(){
    this.getOrderData()
  },

  methods: {
    //获取购买记录
    getOrderData(){
      let params = {
        page:this.pageIndex,
        limit:50,
      }
      if(this.state) return false
      getOrderList(params).then(res=>{
        if(res.code == 1){
          let arr = res.data.list
          if(arr.length == 0) {
            this.state = true
          }
          for(let item in arr){
            this.orderList.push(arr[item])
            // this.$set(this.OrderInfo,'list',arr[item])
          }
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },

    eventScrooll(e){
      if (Math.round(e.srcElement.scrollTop + 1 ) + e.srcElement.clientHeight >= e.srcElement.scrollHeight && e.srcElement.scrollHeight != 0) {
        this.pageIndex++
        // this.getshareRecordData()
        this.getOrderData()

      }
    },
  },

}

</script>
<style lang='less' scoped>
@import '@assets/css/profile/shop/purchaseRecord.less';
</style>